import React, {/* useEffect, */  useState } from "react"
//import Avatar from '@mui/material/Avatar';
//import Button from '@mui/material/Button';
//import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import PasswordChecklist from "react-password-checklist"
import {  /*SignUpOutput, */  signUp } from "aws-amplify/auth";
//import { SignUpConfirmation } from './SignUpConfirmation';
//import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';

import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg'
//import { signIn, signOut, getCurrentUser, fetchAuthSession, fetchUserAttributes, signInWithRedirect } from "aws-amplify/auth";

import { HeaderContent } from '../../app/sharedcomponents';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { FormProvider, useForm } from "react-hook-form";
import { EmailPasswordFields, FormValuesEmailSignIn } from "./SigninEmail";
import { LoginErrorInfo, SignInWithFB } from "./utils";
import { SignUpConfirmation } from "./SignUpConfirmation";
import { SignUpReConfirmation } from "./SignUpReConfirmation";
import { /*Navigate,*/ useNavigate } from "react-router-dom";
//import awsConfig from '../../../src/aws-exports';
import { resendSignUpCode } from "aws-amplify/auth";
import { getAwsConfigForBase } from "../..";

function UserAlreadyExists(props: any) {
  if (props.exist) {
    return (
      <Typography color="error">Email is already registered</Typography>
    );
  }
  return <Box></Box>;
}

export const SignUp: React.FC = () => {


  const [passwordAgain, setPasswordAgain] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [signUpConfirmation, setSignUpConfirmation] = useState(false);
  const [signUpReConfirmation, setSignUpReConfirmation] = useState(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const [signUpEmail, setSignUpEmail] = useState("");
  const [userAlreadyExists, setUserAlreadyExists] = useState(false);

  const [signInLoading, setSignInLoading] = useState(false);
  //const [loginError, setLoginError] = useState();
  const [nonFormMessage, setNonFormMessage] = useState<LoginErrorInfo>();
  const navigate = useNavigate();

  const methods = useForm<FormValuesEmailSignIn>();
  const { control, watch, handleSubmit, formState: { errors }, getValues } = methods

  async function handleEmailSignup() {
    //event.preventDefault();
    //const data = new FormData(event.currentTarget);
    const data = getValues()

    async function handleResendConfirmation() {
      const data = getValues()
      ////console.log(awsConfig)
      try {
        const input = { // ResendConfirmationCodeRequest
          clientId: getAwsConfigForBase().aws_user_pools_web_client_id,//awsConfig.aws_user_pools_web_client_id,
          username: data.email
        };
        //console.log(input)
        //const outret =
        const result = await resendSignUpCode(input);

        if (result.destination) {
          return true
        } else {
          return false
        }
        
      } catch (errorSignIn) {
        console.log(errorSignIn)
        return false
      }
    };


    if (validPassword) {
      try {
        // set loading to true before calling API
        setSignUpLoading(true);
        const response = await handleCompleteSignUp({ password: data.password, email: data.email });
        //console.log(response);

        if ('signUpStep' in response['message']) {
          if (response['message']['signUpStep'] === 'CONFIRM_SIGN_UP') {
            setUserAlreadyExists(false);
            setSignUpConfirmation(true);
            setSignUpReConfirmation(false);
            setSignUpEmail(data.email);
          }
        } else if ('name' in response['message']) {
          if (response['message']['name'].includes('UsernameExistsException')) {
            if (response['isSignUpComplete'] === true) {
              setUserAlreadyExists(true);
              setSignUpLoading(false);
              setSignUpConfirmation(false);
              setSignUpReConfirmation(false);
            } else {
              setUserAlreadyExists(true);
              setSignUpLoading(false);
              setSignUpConfirmation(false);
              const didResendConfirmation = await handleResendConfirmation()
              if (didResendConfirmation) {setSignUpReConfirmation(true);}
            }
          }
        } else {
          setUserAlreadyExists(false);
          setSignUpLoading(false);
          setSignUpConfirmation(false);
          setSignUpReConfirmation(false);
        }

      } catch (error) {
        // add error handling here
        setSignUpLoading(false);
        setSignUpConfirmation(false);
        setSignUpReConfirmation(false);
        console.log(error);
      }
    }

  };



  const handleCompleteSignUp: (arg: { password: string, email: string }) => Promise<{ isSignUpComplete: boolean, message: any }> = async ({ password, email }) => {
    try {
      const { isSignUpComplete, userId, nextStep } = await signUp({
        username: email.toLowerCase(),
        password: password,
        options: {
          userAttributes: {
            email: email.toLowerCase(),
            nickname: email.toLowerCase(),
          },
        }
      });

      console.log(userId);
      return ({ 'isSignUpComplete': isSignUpComplete, 'message': nextStep });
    } catch (error) {
      console.log('error signing up:', error);
      return ({ 'isSignUpComplete': false, 'message': error });
    }
  }
  //    <ThemeProvider theme={defaultTheme}>
  const password = (watch('password'))??"";
  return (
    <FormProvider {...methods}>
    <Container component="main" maxWidth="sm" sx={{height:'100%', pt:4, pb:4}}>
        <Stack
          direction="column"
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap={2}
          sx={{ border: 1, borderColor: '#DEDEDE', borderRadius: 2, p: 4 }}
        >
          <SignUpConfirmation open={signUpConfirmation} email={getValues().email} />
          <SignUpReConfirmation open={signUpReConfirmation} email={getValues().email} />
          <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
            Sign up
          </Typography>
          <LoadingButton
            loading={signInLoading}
            type="submit"
            fullWidth
            disableElevation
            variant="contained"
            sx={{ backgroundColor: '#0866FF', borderRadius:8 }}
            onClick={() => SignInWithFB(setSignInLoading, setNonFormMessage)/*signInWithRedirect({ provider: 'Amazon' })*/}
            startIcon={<SvgIcon component={FacebookIcon} inheritViewBox />}
          >
            <span>sign up with facebook</span>
          </LoadingButton>

          <Divider sx={{ width: '100%' }}>or</Divider>
       
            <Box component="form" onSubmit={handleSubmit(() => { })} noValidate>

              <EmailPasswordFields />

              <TextField
                onChange={(e) => {
                  setPasswordAgain(e.target.value);
                }}
                margin="normal"
                required
                fullWidth
                name="password2"
                label="Password Confirm"
                type="password"
                id="password2"
              />
              <UserAlreadyExists exist={userAlreadyExists} />
              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                iconSize={14}
                onChange={(isValid) => {
                  if (isValid) { setValidPassword(true) }
                  else { setValidPassword(false) }
                }}
                style={{ fontSize: '14px' }}
              />
              <LoadingButton
                loading={signUpLoading}
                type="submit"
                fullWidth
                disableElevation
                variant="contained"
                onClick={handleSubmit(handleEmailSignup)}
                sx={{ backgroundColor: 'black', borderRadius:8 }}
              >
                Sign up
              </LoadingButton>
            </Box>
            <Link sx={{ cursor: 'pointer' }} variant="body2" onClick={()=>navigate("/Sign-In")}>
              {"Already have an account? Sign in"}
            </Link>
        </Stack>
    </Container>
    </FormProvider>
  );
}
// <Link href="/map/Sign-In" variant="body2">
// </ThemeProvider>